<template>
  <div>
    <!--관리자용 현장 선택-->
    <div v-if="userType == 'Admin'">
      <div class="title flexB">
        <h1>{{ $t("overall-site-control") }}</h1>
        <div>
          <!--        <span
                    >{{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}</span
                  >-->
<!--          <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>-->
        </div>
      </div>
      <div class="contents liveList">
        <div class="box one filter">
          <div class="flex">

            <p class="bold">{{ $t("search-keyword-title") }}</p>
            <select v-model="keywordType">
              <option value="all">{{ $t("search-keyword-all") }}</option>
              <option value="title">{{ $t("event-data-site-name") }}</option>
              <option value="address">{{ $t("event-data-site-addr") }}</option>
              <option value="viewId">{{ $t("event-data-site-num") }}</option>
              <option value="departmentName">{{ $t("event-data-site-dept") }}</option>
              <option value="managerName">{{ $t("event-data-site-manager") }}</option>
            </select>
            <input
                type="text"
                v-model="keyword"
                @keydown.enter="search"
            />
            <div class="buttonWrap" style="display: flex; margin-top: 0;">
              <button class="point medium" @click="search" style="width: 120px; margin-left: 20px;">
                {{ $t("btn-searching") }}
              </button>
              <button class="medium margin6" @click="reset" style="width: 120px;">
                {{ $t("btn-reset") }}
              </button>
            </div>
          </div>

          <div class="flex">
            <p class="bold">{{ $t("search-regdate-title") }}</p>
            <Datepicker
                v-model="startYmd"
                :language="ko"
                :format="customFormatter"
                placeholder="YYYY-MM-DD"
            ></Datepicker>
            <span>~</span>
            <Datepicker
                v-model="endYmd"
                :language="ko"
                :format="customFormatter"
                placeholder="YYYY-MM-DD"
            ></Datepicker>
          </div>
          <div class="flexC">
            <p class="bold">{{ $t("event-data-site-status") }}</p>
            <label
            ><input
                type="radio"
                name="status"
                v-model="statuses"
                :value="`Open,Close`"
                checked
            />{{ $t("event-data-status-all") }}</label
            >
            <label style="display: none;"
            ><input
                type="radio"
                name="status"
                v-model="statuses"
                value="Ready"
            />{{ $t("event-data-status-ready") }}</label
            >
            <label
            ><input
                type="radio"
                name="status"
                v-model="statuses"
                value="Open"
            />{{ $t("event-data-status-on-going") }}</label
            >
            <label
            ><input
                type="radio"
                name="status"
                v-model="statuses"
                value="Close"
            />{{ $t("event-data-status-close") }}</label
            >
          </div>

        </div>
        <div class="boxCol2-2 flexB">
          <div class="box flexL" style="width: calc(60% - 2px); height: 610px;">
            
            <h2 class="bold">
              {{ $t("data-total") }} <span class="blue">{{ total }}</span
            >{{ $t("data-case-ea") }}
            </h2>
            <div class="tab2Box" style="width: 100%; height: 470px;">
              <table>
                <tr>
                  <th>No</th>
                  <th>{{ $t("event-data-site-dist") }}</th>
                  <th>{{ $t("event-data-site-name") }}</th>
                  <th>{{ $t("event-data-site-dept") }}</th>
                  <th>{{ $t("event-data-site-manager") }}</th>
                  <th>{{ $t("event-data-site-status") }}</th>
                  <th>{{ $t("event-data-site-regdate") }}</th>
                </tr>
                <colgroup>
                  <col style="width: 5%;"/>
                  <col style="width: 10%"/>
                  <col style="width: 20%;"/>
                  <col style="width: 10%"/>
                  <col style="width: 10%;"/>
                  <col style="width: 10%"/>
                  <col style="width: 15%;"/>
                </colgroup>
                <tr
                    v-for="(data, i) in eventList"
                    :key="i"
                    @click="handleSelectEl(data, i)" :class="{ active: data.eventId == (selectData != null && selectData.eventId) }">
                  <!--                  @click="handleEvent(data)"-->

                  <td>{{ total - (size * currentPage + i) }}</td>
                  <td>{{ data.address.split(" ")[0] }}</td>
                  <!--                <td>{{ data.viewId != null ? data.viewId : data.eventId }}</td>-->
                  <td>{{ data.title }}</td>
                  <!--                <td>{{ data.address + " " + data.addressDetail }}</td>-->
                  <td>
                    {{ data.managerDepartmentTitle}}
                  </td>
                  <td>{{ data.managerName }}</td>
                  <td>{{
                      data.status == "Ready"
                          ? $t("event-data-status-ready")
                          : data.status == "Open"
                              ? $t("event-data-status-on-going")
                              : data.status == "Close"
                                  ? $t("event-data-status-close")
                                  : "-"
                    }}
                  </td>
                  <td>{{ dateFormat(data.registerDate) }}</td>
                </tr>
                <tr class="nodata" v-if="eventList.length == 0">
                  <td colspan="7">{{ $t("search-not-found") }}</td>
                </tr>
              </table>
              <div class="pagination" style="margin-top: 20px;">
                <el-pagination
                    small
                    layout="prev, pager, next"
                    :total="total"
                    :page-size="size"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage + 1"
                >
                </el-pagination>
              </div>
            </div>
          </div>

          <div class="box  " style="width: calc(40% - 2px); height: 610px;">
            <h2 class="bold">
              {{ $t("title-detail-info") }}
            </h2>
            <table>
              <colgroup>
                <col style="width: 25%;"/>
                <col style="width: 75%;"/>
              </colgroup>
              <tr>
                <td>
                  <p class="bold">{{ $t("event-data-site-num") }}</p>
                </td>
                <td>{{ selectData != null ? selectData.eventId : '-' }}</td>
              </tr>
              <tr>
                <td><p class="bold">{{ $t("event-data-site-name") }}</p></td>
                <td style="text-decoration-line: none;">{{ selectData != null ? selectData.title : '-' }}</td>
              </tr>
              <tr>
                <td>
                  <p class="bold">{{ $t("event-data-site-regdate") }}</p>
                </td>
                <td>{{ selectData != null ? moment(selectData.registerDate).format("YYYY-MM-DD HH:mm") : '-' }}</td>
              </tr>
              <tr>
                <td>
                  <p class="bold">{{ $t("event-data-site-addr") }}</p>
                </td>
                <td>{{ selectData != null ? selectData.address + " " + selectData.addressDetail : '-' }}</td>
              </tr>
              <tr>
                <td>
                  <p class="bold">{{ $t("event-data-site-dept") }}</p>
                </td>
                <td>{{ selectData != null ? selectData.managerDepartmentTitle : '-' }}</td>
              </tr>
              <!--              <tr>
                              <td><p class="bold">{{ $t("device-data-manager-flight-num") }}</p></td>
                              <td>{{ selectData != null ? selectData.joinCount : '-' }}</td>
                            </tr>-->
              <!--                <tr>
                                <td><p class="bold">{{ $t("device-data-working-last-flight-time") }}</p></td>
                                <td>{{ selectData != null ? moment(selectData.endTime).format("YYYY-MM-DD HH:mm") : '-' }}</td>
                              </tr>-->
              <tr>
                <td colspan="2" style="text-align: center; border: none; background: white;">
                  <div class="mapWrap" style="width: 100%;">

                    <div class="map" style="min-height: 252px;">
                      <Map style="width: 100%; height: 100%; position: absolute;" ref="map" :defaultZoomLevel="12" :isRightLngLat="true" :isLeftButtonPosition="false" :loadMapName="loadMap" @map-move-center="onMapCenter"/>
                    </div>
                  </div>
                </td>
              </tr>
              <tr style="width: 100%; background: white; position: relative; left: 40%;">
                <button class="point medium" v-if="selectData != null" @click="handleEvent(selectData)" style="width: 120px; margin-top: 20px; padding: 0;">
                  {{ $t("event-move-to-this-site") }}
                </button>
              </tr>
            </table>
          </div>

        </div>
      </div>
    </div>

    <!--일반 회원용 현장 선택(홈)-->
    <div v-else-if="!isAdmin">
      <UserHome />
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Map from "@/components/map/Map";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { fetchEventList } from "@/api/event";
import { mapState } from "vuex";
import UserHome from "./LiveEventListUser";
import MapDataType from "../../components/map/enum/MapDataType";
/*import {newTab} from "@/util/popup";*/

export default {
  components: { Datepicker , UserHome, Map },
  computed: {
    ...mapState({
      isAdmin: state => state.store.isAdmin,
      userType: state => state.store.userType,
    }),
  },
  name: "SiteControl",
  data() {
    return {
      ko: ko,
      editMode: "",
      moment: moment,
      startYmd: null,
      endYmd: null,
      eventList: [],
      loadMap : 'kakao',
      total: 0,
      size: 10,
      currentPage: 0,
      keywordType: "all",
      keyword: null,
      statuses: "Open",
      selectedId: null,
      selectData: null,
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 2, sub: 0 });

    /*관리자 접근 막음*/
    if(this.isAdmin){
      alert("잘못된 접근 입니다.");
      this.$router.push({path : `/${this.accountId}/login`})
      //this.$router.go(-1)
    }
  },
  watch : {
  },
  mounted() {
    this.getEventList();
    localStorage.removeItem("LiveEventInfoMain");
    localStorage.removeItem("LiveEventInfoSub");
  },
  methods: {
    
    onMapCenter() {
      if (this.selectData == null) {
        alert(this.$t("alert-message-choose-site"));
        return;
      }
      this.$refs.map.onLngLatToMoveCenter(this.selectData.lng, this.selectData.lat);
      this.$refs.map.onCreateMapMarker(this.eventId, this.selectData.lng, this.selectData.lat, MapDataType.ID_SITE);
    },
    handleSelectEl(data, i) {
      i;
      this.selectData = data;
      this.$refs.map.onSetZoomLevel(6);
      this.$refs.map.onLngLatToMoveCenter(this.selectData.lng, this.selectData.lat);
      this.$refs.map.onSetLngLat(this.selectData.lng, this.selectData.lat);
      this.$refs.map.onCreateMapMarker(this.eventId, this.selectData.lng, this.selectData.lat, MapDataType.ID_SITE);
    },
    dateFormat(date) {
      return moment(date).format("YYYY.MM.DD HH:mm:ss");
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    handleEvent(data) {
      if(!this.$store.state.store.isAdmin && data.managerDepartmentId != this.$store.state.store.departmentId){
        if(data.isAuth == true && data.authCount == 0) {
          alert(this.$t("user-not-permission"));
          return;
        }
      }

      this.$router.push({ path: `${data.eventId}/SkyView`});

      //Site Control 새창
      /*this.routeUrl = this.$router.resolve({ path: `${data.eventId}/SkyView`});
      newTab(this.routeUrl.href, `SkyView_${data.eventId}`);*/
    },
    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getEventList();
    },
    search(){if(
        this.startYmd != null || this.endYmd != null) {
      if (this.startYmd == null || this.endYmd == null || this.startYmd > this.endYmd) {
        return alert(this.$t("alert-message-check-regdate"));
      }
    }
      this.currentPage = 0;
      this.getEventList();
    },
    async getEventList() {
      var params = {
        pageNumber: this.currentPage,
        pageSize: this.size,
        keywordType: this.keywordType,
        keyword: this.keyword,
        statuses: this.statuses,
      };

      if(this.startYmd != null && this.endYmd != null) {
        params["dateRange.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["dateRange.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }

      fetchEventList(params).then((res) => {
        this.total = res.data.data.total;
        this.eventList = res.data.data.content;
      });
    },
    reset(){//변수 초기화
      this.startYmd = null;
      this.endYmd = null;
      this.currentPage = 0;
      this.keywordType = "all";
      this.keyword = null;
      this.statuses = "Open";
    },
    handleRefresh() {
      this.reset();
      this.getEventList();
    },
  },
};
</script>
